























































import Vue from 'vue';
import Backbone from '../core/Backbone';
import Component from 'vue-class-component';
import DTNotification from '@/models/DTNotification';
import _ from 'lodash';

@Component({
	components: {}
})
export default class WebNotifications extends Vue {
	editItem: DTNotification | null = null;
	canEdit = true;
	notifications: DTNotification[] = [];
	valid: boolean = false;

	rules = {
		required: (v: string) => {
			return v == undefined || v == '' ? 'Feltet skal udfyldes!' : true;
		}
	};

	async mounted() {
		this.canEdit = Backbone.State.User.IsSysAdmin;

		const notResponse = await Backbone.RemoteActions.Other.Notifications_GetAll();
		if (notResponse.status < 300) {
			this.notifications = notResponse.data.sort((a, b) => b.CreatedDate.valueOf() - a.CreatedDate.valueOf());
		}
	}

	setEditItem(item: DTNotification) {
		this.editItem = _.cloneDeep<DTNotification>(item);
	}

	formFailed(): boolean {
		(this.$refs.form as any).validate();
		return !this.valid;
	}

	async saveItem() {
		if (this.formFailed()) {
			return;
		}
		if (this.editItem?.Id != '') {
			const response = await Backbone.RemoteActions.Other.Notifications_Save(this.editItem!);
			if (response.status < 300) {
				Backbone.UI.PushAlert('Ændringer til notifikation er gemt', 'success', true);
				const index = this.notifications.findIndex((item) => item.Id == this.editItem?.Id);
				this.notifications[index] = response.data;
				this.editItem = null;
			} else {
				Backbone.UI.PushApiErrorAlert('Der skete en fejl ved gemning af notifikationen', response, 'error', true);
			}
		} else {
			const response = await Backbone.RemoteActions.Other.Notifications_Create(this.editItem!);
			if (response.status < 300) {
				Backbone.UI.PushAlert('Notifikationen er oprettet', 'success', true);
				this.notifications.unshift(response.data);
				this.editItem = null;
			} else {
				Backbone.UI.PushApiErrorAlert('Der skete en fejl ved opretning af notifikationen', response, 'error', true);
			}
		}
	}

	async deleteItem() {
		const confirmResp = await Backbone.UI.OpenConfirmDialog('Slet notifikation', 'Slet notification - er du sikker?', true);

		if (!confirmResp) {
			return;
		}
		const response = await Backbone.RemoteActions.Other.Notifications_Delete(this.editItem!.Id);
		if (response.status < 300) {
			Backbone.UI.PushAlert('Notifikationen er slettet', 'success', true);
			const index = this.notifications.findIndex((item) => item.Id == this.editItem?.Id);
			this.notifications.splice(index, 1);
			this.editItem = null;
		} else {
			Backbone.UI.PushApiErrorAlert('Der skete en fejl ved sletning af notifikationen', response, 'error', true);
		}
	}

	newlineAsBreaks(text: string) {
		return text.replaceAll('\n', '<br />');
	}

	insertItem() {
		this.editItem = new DTNotification();
	}
}
